// Form validation
const newsletterForm = document.querySelector('.js-newsletter-form');

const message = document.querySelector('.js-newsletter-message');

const firstNameField = document.querySelector('.js-newsletter-first-name');
const lastNameField = document.querySelector('.js-newsletter-last-name');
const emailField = document.querySelector('.js-newsletter-email');
const privacyCheckbox = document.querySelector('.js-privacy-checkbox');
const privacyLabel = document.querySelector('.js-privacy-label');
const newsletterModal = document.querySelector('.js-newsletter-modal');
const successClose = document.querySelector('.js-newsletter-success-close');

const modalCheckbox = document.querySelector('.js-modal-checkbox');
const modalClose = document.querySelector('.js-modal-close');

// Newsletter validation texts
const emailMessage = 'Please enter a valid email.';
const privacyMessage = 'Please accept the privacy policy.';
const invalidMessage = 'Please fill out all fields.';

// Form submit listener
newsletterForm?.addEventListener('submit', (e) => {
	checkValidity();
});

// Success close button
successClose?.addEventListener('click', (e) => {
	document.body.classList.remove('was-successful');
});

// Automatic success close
function closeSuccessMessage(){
	setTimeout(() => {
		modalCheckbox.checked = false;
		document.body.classList.remove('was-successful');
	}, 4000);
}

// Helper function to determine valid email input
function validMail(mail) {
    return /^[\w._-]+[+]?[\w._-]+@[\w.-]+\.[a-zA-Z]{2,6}$/.test(mail);
}

// Modal close
modalClose?.addEventListener('click', (e) => {
	document.body.classList.contains('was-successful') ? document.body.classList.remove('was-successful') : null;
});

// Check mailchimp form validity
function checkValidity() {
	message.innerHTML = '';
	
	// Not valid
    if (newsletterForm.checkValidity() == false) {
		message.classList.add('newsletter__message--error');

		// Check for name input fields
		if (firstNameField.value.length == 0 || lastNameField.value.length == 0) {
			message.innerHTML = invalidMessage;
			
			// Focus the empty field
			firstNameField.value.length == 0 ? firstNameField.focus() : lastNameField.focus();

		// Check for email input field
		} else if (!validMail(emailField.value)) {
			message.innerHTML = emailMessage;
			emailField.focus();

		// Check for privacy input field
		} else if (!privacyCheckbox.checked) {
			message.innerHTML = privacyMessage;

		// Fallback
		} else message.innerHTML = invalidMessage;

	// Valid
    } else {
        document.body.classList.add('was-successful');
		message.classList.remove('newsletter__message--error');
		newsletterForm.reset();
		closeSuccessMessage();
    }
}

// Check privacy box
privacyLabel?.addEventListener('click', (e) => {
	e.preventDefault();
	
	privacyCheckbox.checked = !privacyCheckbox.checked;
});